// import Aos from "aos";
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const F = () => {
    const courselist = useRef();
    useEffect(() => {
        let footerlist = '';
        fetch('https://api.thevoicebusiness.com.au/courses').then(r => r.json())
        .then(jr => {
            jr.map((j) => footerlist += `<div class="col-12 col-md-4 py-1"><a href="/course/${j.url}">${j.title}</a></div>`);
            courselist.current.innerHTML = footerlist;
        });
    }
        , []);
    // Aos.init();
    return <footer className="mt-4">
    <div className="footer-wrapper">
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <div className="left-content">
                        <div className="logo pb-5">
                            <a href="/">
                                <img src="/images/logo.svg" alt="logo" width={"320"} height={"81"} />
                            </a>
                        </div>
<p className="my-5">
<strong>Head Office</strong><br />
Level 3,<br />
66 Berry Street,<br />
North Sydney<br />
NSW 2060,<br />Australia</p>
<p className="my-5">
<strong>All Correspondence to:</strong><br />
P.O. Box 179,<br />
Crows Nest,<br />
NSW 1585,<br />Australia</p>
                        <div className="social-media-wrapper">
                            <strong>Social Media</strong>
                            <div className="d-flex icons-wrapper mt-3">
                                <a rel="noopener noreferrer external" target="_blank" href="https://www.facebook.com/TheVoiceBusiness"><img width="32" height="32" className="w32" src="/images/facebook.svg" alt="facebook-icon" /></a>
                                <a rel="noopener noreferrer external" target="_blank" href="https://instagram.com/thevoicebusiness"><img width="32" height="32" className="w32" src="/images/instagram.svg" alt="instagram-icon" /></a>
                                <a rel="noopener noreferrer external" target="_blank" href="https://www.youtube.com/user/VBTVChannel"><img width="32" height="32" className="w32" src="/images/youtube.svg" alt="youtube-icon" /></a>
                                <a rel="noopener noreferrer external" target="_blank" href="https://www.linkedin.com/company/thevoicebusiness/"><img width="32" height="32" className="w32" src="/images/linkedin.svg" alt="linkedin-icon" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="right-content">
                        <div className="box-border">
                            <h5 className="f-title">The Site</h5>
                            <div className="col-lg-10">
                                <ul className="menu-top mb-0">
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/contact">Contact Us</a></li>
                                    <li><a href="/booking">Booking</a></li>
                                    <li><a href="/national-program">Courses</a></li>
                                    <li><a href="/press">Press/Blog</a></li>
                                    <li><a href="/page/privacy">Privacy</a></li>
                                    <li><a href="/page/terms">Terms and conditions</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="box-border">
                            <h5 className="f-title">Courses</h5>
                            <div className="row" ref={courselist}>
                            </div>
                        </div>

                        <div className="box-border">
    <h5 className="f-title">Regions - Domestic</h5>
    <div className="row">
        <div className="col-md-6 col-12 py-1">
<Link to="/domestic/sydney" title="Voice coaching and training in Sydney">Voice coaching and training Sydney</Link>
        </div>
        <div className="col-md-6 col-12 py-1">
<Link to="/domestic/melbourne" title="Voice coaching and training in Melbourne">Voice coaching and training Melbourne</Link>
        </div>
        <div className="col-md-6 col-12 py-1">
<Link to="/domestic/brisbane" title="Voice coaching and training in Brisbane">Voice coaching and training Brisbane</Link>
        </div>
        <div className="col-md-6 col-12 py-1">
<Link to="/domestic/perth" title="Voice coaching and training in Perth">Voice coaching and training Perth</Link>
        </div>
        <div className="col-md-6 col-12 py-1">
<Link to="/domestic/adelaide" title="Voice coaching and training in Adelaide">Voice coaching and training Adelaide</Link>
        </div>
        <div className="col-md-6 col-12 py-1">
<Link to="/domestic/canberra" title="Voice coaching and training in Canberra">Voice coaching and training Canberra</Link>
        </div>
    </div>
                        </div>

                        <div className="box-border">
                        <h5 className="f-title">Regions - International</h5>
                            <div className="row">
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/china">China</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/india">India</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/japan">Japan</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/hk">Hong Kong</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/korea">Korea</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/malaysia">Malaysia</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/vietnam">Vietnam</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/singapore">Singapore</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/philippines">Philippines</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/ukeu">UK & Europe</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/emirates">Middle East</Link>
                                </div>
                                <div className="col-md-4 col-6 py-1">
                                    <Link to="/international/nz">New Zealand</Link>
                                </div>
                            </div>
                        </div>

                        <div className="box-border border-0">
                        <h5 className="f-title">Network</h5>
                        <div className="row">
                            <div className="col-md-4 col-12 py-2">
                                <a href="https://www.vocalintelligence.org" target="_blank" className="d-block mb-2 fw-bold">Vocal Intelligence</a>
                                <small>Develop vocal capacity to speak with power and confidence.</small>
                            </div>
                            <div className="col-md-4 col-12 py-2">
                                <a href="https://www.voicebusinesselite.com.au" target="_blank" className="d-block mb-2 fw-bold">Voice Business Elite</a>
                                <small>Specially designed courses for C-Suite & Senior Executives.</small>
                            </div>
                            <div className="col-md-4 col-12 py-2">
                                <a href="https://www.voiceover.com.au" target="_blank" className="d-block mb-2 fw-bold">Voiceovers</a>
                                <small>Hire talent for your next big project from the variety of voice artists.</small>
                            </div>
                            <div className="col-md-4 col-12 py-2">
                                <a href="https://www.elocutionlessons.com.au" target="_blank" className="d-block mb-2 fw-bold">Elocution Lessons</a>
                                <small>Our proprietary method to upgrade your English expression skills.</small>
                            </div>
                            <div className="col-md-4 col-12 py-2">
                                <a href="https://www.presentingonline.com" target="_blank" className="d-block mb-2 fw-bold">Presenting Online</a>
                                <small>Voice skills for Virtual Presenters who present webinars and presentations.</small>
                            </div>
                            <div className="col-md-4 col-12 py-2">
                                <a href="https://www.voicecoach.net" target="_blank" className="d-block mb-2 fw-bold">Podcasting Classes</a>
                                <small>From setting up a home studio to delivering engaging content.</small>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="copy-right-wrapper">
        <p>Copyright © <span id="currentYear">{new Date().getFullYear()}</span> The Voice Business. All Rights Reserved.</p>
        <p><img src="/images/tvb.svg" width="32" height="32" alt="short logo" className="w32" /> The Voice Business&reg; is a registered trademark</p>
    </div>
</footer>
}

export default F;